import React from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <nav className="NavBar">
      <Link to="/" className="Logo">
        <span className="LogoText">
          <span>FRC</span>
          <span>Ladder</span>
        </span>
      </Link>
      <ul className="NavLinks">
        <li className="NavLink">
          <Link to="/">Home</Link>
        </li>
        <li className="NavLink">
          <Link to="/courses">Courses</Link>            
        </li>
        <li className="NavLink">
          <Link to="/about">About</Link>
        </li>
        {/* Add more navigation links as needed */}
      </ul>
    </nav>
  );
}


export default NavBar;
