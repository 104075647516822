import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/home';
import Courses from './views/courses';
import Course from './views/course';
import About from './views/about';
import React, { useEffect } from 'react';
import NavBar from './components/NavBar';
import ReactGA from 'react-ga4';


ReactGA.initialize('G-7DE6BR46BM');

function App() {

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/about" element={<About />} />
        {/* Add more routes for other pages */}
        <Route path="/course/:id" element={<Course />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
