import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <section className="Hero">
      <div className="HeroContent">
        <h1 className="HeroTitle">Welcome to FRCLadder - Robotics Education for FRC</h1>
        <p className="HeroSubtitle">Learn Robotics for FIRST Robotics Competition (FRC)</p>
      </div>
    </section>
  );
}

export default Hero;
