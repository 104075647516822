import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './course.css';
import Confetti from 'react-confetti';

interface Chapter {
  title: string;
  content: string;
  videoUrls?: string[];
}

interface CourseData {
  title: string;
  description: string;
  chapters?: Chapter[];
}

interface CourseParams extends Record<string, string | undefined> {
  id: string;
}

function Course() {
  const navigate = useNavigate();
  const { id } = useParams<CourseParams>();

  const [selectedChapter, setSelectedChapter] = useState<number | null>(0);
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [completedChapters, setCompletedChapters] = useState<string[]>(() => {
    const storedCompletedChapters = localStorage.getItem(`completedChapters-${id}`);
    return storedCompletedChapters ? JSON.parse(storedCompletedChapters) : [];
  });
  const videoRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    localStorage.setItem(`completedChapters-${id}`, JSON.stringify(completedChapters));
    localStorage.setItem(`courseCompletionPercentage-${id}`, calculateCourseCompletionPercentage().toString());
  }, [id, completedChapters]);

  const handleChapterClick = (chapterIndex: number) => {
    setSelectedChapter((prevChapterIndex) =>
      prevChapterIndex === chapterIndex ? null : chapterIndex
    );
  };

  const [isNavigating, setIsNavigating] = useState(false);

  const handleNextChapter = () => {
    if (isNavigating) return;

    if (selectedChapter !== null && selectedChapter < chapters.length - 1) {
      const currentChapterTitle = chapters[selectedChapter].title;
      if (!completedChapters.includes(currentChapterTitle)) {
        setCompletedChapters([...completedChapters, currentChapterTitle]);
      }
      setSelectedChapter(selectedChapter + 1);
    } else if (selectedChapter === chapters.length - 1) {
      const lastChapterTitle = chapters[selectedChapter].title;
      if (!completedChapters.includes(lastChapterTitle)) {
        setCompletedChapters([...completedChapters, lastChapterTitle]);
      }

      setIsConfettiActive(true);
      setIsNavigating(true);
      const updatedCompletedChapters = [...completedChapters, lastChapterTitle];

      const newCompletionPercentage = calculateCourseCompletionPercentage();

      localStorage.setItem(`courseCompletionPercentage-${id}`, newCompletionPercentage.toString());

      setTimeout(() => {
        navigate('/');
        setIsNavigating(false);
      }, 4000);
    }
  };

  const courseData = require(`../../data/${id}/course.json`) as CourseData;

  if (!courseData || !courseData.chapters) {
    return <div>Course not found</div>;
  }

  const { title, description, chapters } = courseData;

  const isLink = (line: string) => {
    const linkRegex = /<a\b[^>]*>(.*?)<\/a>/;
    return linkRegex.test(line);
  };

  const renderContent = (content: string) => {
    const lines = content.split('\n');
    return lines.map((line, index) => {
      if (isLink(line)) {
        return (
          <div key={index} dangerouslySetInnerHTML={{ __html: line }} />
        );
      }
      return (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      );
    });
  };

  const isMobileView = window.innerWidth <= 768;

  const calculateCourseCompletionPercentage = () => {
    if (chapters.length === 0) {
      return 0;
    }
  
    const completedChapterCount = completedChapters.length;
    const totalChapterCount = chapters.length;
    const percentage = (completedChapterCount / totalChapterCount) * 100;
    return Math.round(percentage);
  };
  

  const storedCompletionPercentage = localStorage.getItem(`courseCompletionPercentage-${id}`);
  const courseCompletionPercentage = storedCompletionPercentage !== null ? parseInt(storedCompletionPercentage) : 0;

  return (
    <div className="CoursePage">
      {isConfettiActive && <Confetti />}
      <div className="CourseContainer">
        <div className="Sidebar">
          <h2 className="SidebarTitle">{title}</h2>
          <div className="ProgressBar">
          <div className="ProgressFill" style={{ width: `${calculateCourseCompletionPercentage()}%` }}>
            {calculateCourseCompletionPercentage() > 0 && (
              <span className="ProgressText">{calculateCourseCompletionPercentage()}%</span>
            )}
          </div>
        </div>
          {isMobileView ? (
            <>
              <select
                value={selectedChapter || ''}
                onChange={(e) => handleChapterClick(Number(e.target.value))}
                className="dropdown"
              >
                {chapters.map((chapter: Chapter, index: number) => (
                  <option
                    key={index}
                    value={index}
                    className={
                      completedChapters.includes(chapter.title) ? 'completed' : ''
                    }
                  >
                    {chapter.title}
                    {completedChapters.includes(chapter.title) && (
                      <span className="CompletionIcon">&#10004;</span>
                    )}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <>
              <ul className="ChapterList">
                {chapters.map((chapter: Chapter, index: number) => (
                  <li key={index}>
                    <button
                      className={`ChapterButton ${
                        selectedChapter === index ? 'active' : ''
                      } ${
                        completedChapters.includes(chapter.title) ? 'completed' : ''
                      }`}
                      onClick={() => handleChapterClick(index)}
                    >
                      {completedChapters.includes(chapter.title) && (
                        <span className="CompletionIcon">&#10004;</span>
                      )}
                      {completedChapters.includes(chapter.title) ? ' ' : null}
                      {selectedChapter === index && (
                        <span className="ArrowIcon">▶</span>
                      )}
                      {chapter.title}
                    </button>
                  </li>
                ))}
              </ul>
              {!isMobileView && (
                <button className="NextButton" onClick={handleNextChapter}>
                  {selectedChapter === chapters.length - 1 ? 'Finish Course' : 'Next'} ❯
                </button>
              )}
            </>
          )}
        </div>
        <div className="Content">
          {selectedChapter !== null && chapters[selectedChapter] && (
            <div className="ChapterDetails">
              <h3>{chapters[selectedChapter].title}</h3>
              {chapters[selectedChapter].videoUrls && (
                <div className="VideoWrapper">
                  {chapters[selectedChapter].videoUrls?.map((url, index) => (
                    <div className="VideoItem" key={index}>
                      <iframe
                        ref={videoRef}
                        src={url}
                        width="560"
                        height="315"
                        title={`YouTube Video ${index + 1}`}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ))}
                </div>
              )}
              <div>{renderContent(chapters[selectedChapter].content)}</div>
              {isMobileView && (
                <button className="NextButton" onClick={handleNextChapter}>
                  {selectedChapter === chapters.length - 1 ? 'Finish Course' : 'Next'} ❯
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
  }

  export default Course
