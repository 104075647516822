import React from 'react';
import './About.css';

function About() {
  return (
    <div className="About">
      <h1 className="AboutTitle">About FRCLadder - Robotics Education for FRC</h1>
      <p className="AboutContent">
        FRCLadder is an online platform that provides robotics courses for FIRST Robotics
        Competition (FRC) teams. Our mission is to empower students and mentors in the FIRST community
        with the knowledge and skills to excel in the field of robotics.
      </p>

      <section className="WhatWeProvide">
        <h2>What FRCLadder Provides</h2>
        <ul>
          <li>Interactive lessons and tutorials</li>
          <li>Hands-on coding exercises</li>
          <li>Project-based learning</li>
          <li>In-depth explanations of FRC-specific programming concepts</li>
          <li>Best practices for robot control systems and sensors</li>
        </ul>
      </section>

      <h2 className="AboutTitle">Contributors</h2>
      <ol>
        <li>The MidKnight Inventors FRC 1923</li>
        <li>RoboTigers 1796</li>
        <li>Ellipse 6814</li>
        <li>STEAMex 6832</li>
        <li>System Overload Robotics 6059</li>
      </ol>

      <h2 className="AboutTitle">About Me</h2>
      <p className="AboutContent">
        Hi! My name is Hitarth and I am the current president of FRC team 6059 (System Overload Robotics).
        I am the creator of this application and I wanted to create this because of a need I saw in my community. 
        After talking with several teams, there was a clear problem of access to education in the robotics space 
        with teams that are 20+ years old still having a problem with their education program. I decided to 
        create this website with the help of a couple of other teams to make sure everyone has access to an education 
        platform within their community. Please feel free to reach out if you have questions or comments or if you 
        would like to contribute to this site, we are always looking for more people to help grow this community!
      </p>

      <h2 className="AboutTitle">Contact Me</h2>
      <ul>
        <li>ChiefDelphi: hithu</li>
      </ul>
    </div>    
  );
}

export default About;
