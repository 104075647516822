import React from 'react';
import { Link } from 'react-router-dom';
import './courses.css';
import CourseList from '../../data/courses.json';

interface Course {
  id: string;
  title: string;
  description: string;
  chapters?: {
    title: string;
    content: string;
    videoUrls?: string[];
  }[];
}

function Courses() {
  const calculateCourseCompletionStatus = (course: Course) => {
    const storedCompletionPercentage = localStorage.getItem(`courseCompletionPercentage-${course.id}`);
    const courseCompletionPercentage = storedCompletionPercentage !== null ? parseInt(storedCompletionPercentage) : 0;

    if (courseCompletionPercentage === 100) {
      return (
        <span className="Completed">
          Completed <span className="Checkmark">&#10003;</span>
        </span>
      );
    } else if (courseCompletionPercentage > 0) {
      return (
      <span className="Midway">
        {courseCompletionPercentage}% Completed
      </span>
      );
    } else {
      return '';
    }
  };

  return (
    <div className="CoursePage">
      <h1 className="PageTitle">Available Courses</h1>
      <div className="CourseList">
          {CourseList.map((course: Course) => {
            const completionStatus = calculateCourseCompletionStatus(course);

            return (
              <div className="CourseCard" key={course.id}>
                <h2 className="CourseTitle">
                  {course.title}: {completionStatus}
                </h2>
                <p className="CourseDescription">{course.description}</p>
                <Link to={`/course/${course.id}`} className="StartButton">
                  Start Course
                </Link>
                {course.chapters && (
                  <ul>
                    {course.chapters.map((chapter, index) => (
                      <li key={index}>
                        <span>{chapter.title}</span>
                        {/* Display additional chapter details as needed */}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
    </div>
  );
}

export default Courses;
